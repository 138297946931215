import * as React from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

const SModalOverlay = styled.div`
  background-color: #999999;
  height: 100vh;
  left: 0;
  opacity: 0.5;
  position: fixed;
  display: flex;
  top: 0;
  width: 100vw;
  z-index: 500;
`

const SModalWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  left: 0;
  outline: 0;
  position: fixed;
  top: 10%;
  width: 100%;
  z-index: 1000;
`

const SModal = styled.div`
  align-items: center;
  background: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin: 1.875rem;
  max-width: 500px;
  position: relative;
  z-index: 100;
`

const SHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1.875rem 0.9375rem 1.875rem 0.9375rem;
`

const STitle = styled.h1`
  margin-bottom: 0.3125rem;
`

const SButton = styled.button`
  border-top: 1px solid #f0f0f0;
  color: #6d087c;
  cursor: pointer;
  font-weight: bold;
  padding: 0.9375rem;
  width: 100%;
`

const SDescription = styled.span`
  color: black;
  text-align: left;
  overflow: scroll;
  max-height: 60vh;
`

const LymphModal = ({ isVisible, hideModal }) => {
  return isVisible
    ? createPortal(
        <React.Fragment>
          {/* <div className="bg-gray-60 h-full left-0 opacity-0 fixed top-0 w-full z-{500}" /> */}
          <SModalOverlay />
          <SModalWrapper
            aria-modal={true}
            aria-hidden={true}
            tabIndex={-1}
            role="dialog"
          >
            <SModal>
              <SHeader>
                <h5 className="font-bold tracking-normal text-2xl">
                  Was ist manuelle Lymphdrainage
                </h5>
                <SDescription>
                  <p className="mt-4">
                    Neben dem Blutkreislauf gibt es noch ein weiteres
                    Transportsystem: das Lymphgefäßsystem. Hier werden in erster
                    Linie End- und Abfallprodukte des Stoffwechsels
                    transportiert. Nach größeren Operationen und Verletzungen
                    fallen viele solcher lymphpflichtigen Substanzen an.
                  </p>
                  <p className="mt-4">
                    Die manuelle Lymphdrainage ist eine Therapieform der
                    physikalischen Anwendungen und ihre Wirkungsweise ist breit
                    gefächert. So dient sie unter anderem als Ödem- und
                    Entstauungstherapie geschwollener Körperregionen, wie
                    Körperstamm und Extremitäten (Arme und Beine), welche nach
                    Unterleibs- und/oder Brustkrebsoperationen entstehen können.
                    Durch kreisförmige Verschiebetechniken, welche mit leichtem
                    Druck angewandt werden, soll Flüssigkeit aus dem Gewebe in
                    das Lymphgefäßsystem verschoben werden. Die manuelle
                    Lymphdrainage wirkt sich überwiegend auf den Haut- und
                    Unterhautbereich aus und soll keine Mehrdurchblutung, wie in
                    der klassischen Massage, bewirken. Weitere Indikationen sind
                    sämtliche orthopädischen und traumatologischen Erkrankungen,
                    die mit einer Schwellung einhergehen (Verrenkungen,
                    Zerrungen, Verstauchungen, Muskelfaserrisse etc.). Auch bei
                    Verbrennungen, Schleudertrauma, Morbus Sudeck und ähnlichen
                    Krankheitsbildern wird die manuellen Lymphdrainage
                    angewendet.
                  </p>
                </SDescription>
              </SHeader>
              <button
                className="text-primary cursor-pointer font-bold padding p-4"
                onClick={hideModal}
              >
                Schließen
              </button>
            </SModal>
          </SModalWrapper>
        </React.Fragment>,
        document.body
      )
    : null
}

export default LymphModal
