import React from "react"
import {
  IoMedalOutline,
  IoCalendarNumberOutline,
  IoLibraryOutline,
  IoHourglassOutline,
} from "react-icons/io5"

const Features = () => {
  return (
    <section>
      <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-24 lg:py-22 min-h-full ">
        <div className="flex flex-wrap">
          <div className="w-full">
            <div className="mb-12 lg:mb-20 max-w-[820px]">
              <h3 className="mb-8 text-4xl font-bold leading-none tracking-tighter text-primary md:text-7xl lg:text-5xl">
                <span className="text-gray-900"> Eine wirkungsvolle</span>{" "}
                Behandlung
              </h3>
              <div className="text-lg sm:text-xl leading-relaxed sm:leading-relaxed text-body-color">
                <p>
                  Für eine wirkungsvolle Behandlung sind drei Komponenten
                  notwendig:
                  <ul className="list-disc list-inside">
                    <li>Von medizinischer Seite den Arzt</li>
                    <li>Uns als Therapeuten</li>
                    <li>Und Sie als Patienten</li>
                  </ul>
                </p>
                <details class="open:bg-white dark:open:bg-slate-100 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 rounded-lg">
                  <summary>...</summary>

                  <p className="mt-2">
                    Von ärztlicher Seite benötigen wir das Rezept und eine
                    medizinische Diagnose.
                  </p>
                  <p className="mt-2">
                    Von uns als die ausführenden Therapeuten erwarten wir, dass
                    wir uns mit der erhaltenen Diagnose fachlich
                    auseinandersetzen können und als ausführende Partei im
                    Therapieprozess, auf dem neuesten Stand durch
                    Fachkonferenzen und andere Fortbilungen sind.
                  </p>
                  <p className="mt-2">
                    Die wichtigste Partei in diesem 3er Gespann sind allerdings
                    Sie als Patient. Wir versuchen Sie mit ihren Wünschen und
                    Hoffnungen für die Therapie in den Prozess der Heilung mit
                    einzubeziehen. Der Erfolg der Behandlung ist aber imperative
                    davon abhängig, dass Sie die Therapie mittragen und aktiv
                    unterstützen. Je nach Diagnose ist dies unterschiedlich
                    weitreichend. Angefangen bei regelmäßigen Dehnübungen, kann
                    dies auch bedeuten seine Ernährung und Lebenswandel zu
                    überdenken und anzupassen, um eine erfolgreiche und
                    nachhaltige Therapie zu erzielen. Wir stehen Ihnen auf
                    diesem Weg mit unserer ganzen Kompetenz zur Seite.
                  </p>
                </details>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div
            className="w-full md:w-1/2 lg:w-1/4 text-center md:text-left"
            data-sal="fade"
            data-sal-delay="200"
            data-sal-duration="2000"
            data-sal-easing="ease"
          >
            <div className="bg-white mb-12 group">
              {" "}
              <div className=" w-[70px] h-[70px] flex items-center justify-center bg-white rounded-2xl mx-auto md:mx-0 mb-8 relative z-10 ">
                {" "}
                <span className=" w-[70px] h-[70px] flex items-center justify-center bg-secondary rounded-2xl mb-8 absolute z-[-1] top-0 left-0 rotate-[25deg] group-hover:rotate-45 duration-300 "></span>{" "}
                <IoMedalOutline size={40} color="white" />
              </div>
              <h4 className="font-bold text-xl text-dark mb-3">
                1. und bisher einzige
              </h4>
              <p className="text-body-color mb-8 lg:mb-11">
                lymphologische Schwerpunktpraxis im Allgäu
              </p>
            </div>
          </div>
          <div
            className="w-full md:w-1/2 lg:w-1/4 px-4 text-center md:text-left"
            data-sal="fade"
            data-sal-delay="200"
            data-sal-duration="2000"
            data-sal-easing="ease"
          >
            <div className="bg-white mb-12 group wow fadeInUp">
              <div className=" w-[70px] h-[70px] flex items-center justify-center bg-white rounded-2xl mx-auto md:mx-0 mb-8 relative z-10 ">
                {" "}
                <span className=" w-[70px] h-[70px] flex items-center justify-center bg-secondary rounded-2xl mb-8 absolute z-[-1] top-0 left-0 rotate-[25deg] group-hover:rotate-45 duration-300 "></span>
                <IoCalendarNumberOutline size={40} color="white" />
              </div>
              <h4 className="font-bold text-xl text-dark mb-3">
                Seit über 35 Jahren
              </h4>
              <p className="text-body-color mb-8 lg:mb-11">
                begleiten wir Sie und Ihre Problem mit Herz
              </p>
            </div>
          </div>
          <div
            className="w-full md:w-1/2 lg:w-1/4 px-4 text-center md:text-left"
            data-sal="fade"
            data-sal-delay="400"
            data-sal-duration="2000"
            data-sal-easing="ease"
          >
            <div className="bg-white mb-12 group wow fadeInUp">
              <div className=" w-[70px] h-[70px] flex items-center justify-center bg-white rounded-2xl mx-auto md:mx-0 mb-8 relative z-10 ">
                <span className=" w-[70px] h-[70px] flex items-center justify-center bg-secondary rounded-2xl mb-8 absolute z-[-1] top-0 left-0 rotate-[25deg] group-hover:rotate-45 duration-300 "></span>{" "}
                <IoLibraryOutline size={40} color="white" />
              </div>
              <h4 className="font-bold text-xl text-dark mb-3">
                Stets auf dem neuesten Stand
              </h4>
              <p className="text-body-color mb-8 lg:mb-11">
                durch regelmäßige internationale Fortbildung
              </p>
            </div>
          </div>
          <div
            className="w-full md:w-1/2 lg:w-1/4 px-4 text-center md:text-left"
            data-sal="fade"
            data-sal-delay="600"
            data-sal-duration="2000"
            data-sal-easing="ease"
          >
            <div className="bg-white mb-12 group wow fadeInUp">
              <div className=" w-[70px] h-[70px] flex items-center justify-center bg-white rounded-2xl mx-auto md:mx-0 mb-8 relative z-10 ">
                <span className=" w-[70px] h-[70px] flex items-center justify-center bg-secondary rounded-2xl mb-8 absolute z-[-1] top-0 left-0 rotate-[25deg] group-hover:rotate-45 duration-300 "></span>{" "}
                <IoHourglassOutline size={40} color="white" />
              </div>
              <h4 className="font-bold text-xl text-dark mb-3">
                Wir nehmen uns Zeit für Sie{" "}
              </h4>
              <p className="text-body-color mb-8 lg:mb-11">
                und haben einen Therapierhytmus von 1/2 bis 1 Stunde
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
