import React from "react"
import { ParallaxBanner } from "react-scroll-parallax"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { BsArrowDownCircle } from "react-icons/bs"
const Header = () => {
  return (
    <ParallaxBanner
      layers={[
        {
          children: <StaticImage className="h-full w-full" 
            src="../images/hero.jpg"
            placeholder="tracedSVG"
            formats={["auto", "webp", "avif"]}/>,
          speed: -20,
          scale: [1, 1.5, "easeInQuad"],
        },
        {
          speed: -15,
          easing: "easeOutQuad",

          children: (
            <div>
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <h1 className="text-slate-100 font-bold tracking-normal text-7xl text-shadow-sm shadow-slate-300">
                  ZML Allgäu
                </h1>

                <p className="text-slate-100 max-w-4xl mx-auto mt-8 text-xl md:text-3xl font-semibold tracking-wide leading-tight text-center">
                  seit über 35 Jahren, zusammen für Ihre Gesundheit!
                </p>
                <AnchorLink to="/#uber_uns">
                  <BsArrowDownCircle
                    size={40}
                    color="white"
                    className="mt-10 animate-bounce"
                  />
                </AnchorLink>
              </div>

              <div className="fixed top-56 right-10 flex flex-col items-center rotate-12">
                <p className="text-3xl lg:text-7xl font-bold leading-relaxed mt-0 mb-4 backdrop-blur-sm bg-green-500 opacity-60 rounded-3xl p-10 z-50">
                  <Link to="/stelle">Willkommen im Team</Link>
                </p>
              </div>
            </div>
          ),
        },
      ]}
      className="bg-full-parallax"
    ></ParallaxBanner>
  )
}

export default Header
