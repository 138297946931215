import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { headingOne, baseText } from "../utils/typography"

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium">{title}</p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  )
}

const Faq = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-22 lg:px-8 lg:py-20">
      <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div className="flex flex-col mb-16 sm:text-center">
          <a href="/" class="mb-6 sm:mx-auto">
            <div class="flex items-center justify-center w-12 h-12 rounded-full mx-auto md:mx-0">
              <StaticImage
                src="../images/icons/faq_icon.png"
                placeholder="tracedSVG"
              />
            </div>
          </a>
          <div class="max-w-xl md:mx-auto text-center md:text-left lg:max-w-2xl">
            <h2 class={headingOne}>Häufig gestellte Fragen</h2>
            <p class={baseText}>
              Die häufigsten Fragen, die uns dabei begegnet sind und wie Sie
              einen Termin zur Lymphdrainage vereinbaren, haben wir Ihnen in
              unserer Übersicht zusammengestellt:
            </p>
          </div>
        </div>
        <div class="space-y-4">
          <Item title="Wie vereinbare ich einen Termin?">
            Am besten kommen Sie zur vollen oder halben Stunde während unserer
            Kernöffnungszeiten mit ihrem Rezept in der Praxis vorbei. Alternativ
            können Sie uns auch gerne telefonisch kontaktieren.
          </Item>
          <Item title="Wie erreiche ich die Praxis für Lymphdrainage am besten?">
            Zu erreichen sind wir am besten telefonisch zu unseren
            Kernöffnungszeiten zur vollen und halben Stunde.
          </Item>
          <Item title="Was muss ich zur (ersten) Behandlung mitbringen?">
            Zur ersten Behandlung wäre gut Ihre Versichertenkarte parat zu haben und optimalerweise Bargeld um den Eigenanteil zum Rezept gleich begleichen zu können.
          </Item>
          <Item title="Wie lange dauert eine Behandlung?">
            Ja nach Art dauert die Behandlung in der Regel zwischen 30 und 60 Minuten. In Einzelfällen kann es bei der Lymphdrainage auch länger sein.
          </Item>
          <Item title="Wo kann ich parken?">
            Wir haben direkt vor der Praxis Parkplätze und die Räumlickeiten sind im Erdgeschoss barrierefrei zugänglich.
          </Item>
          {/* <Item title="Was passiert, wenn ich zu spät zum Termin erscheine?">
            Die versäumte Zeit wird von der Behandlungszeit abgezogen. Nach einer Wartezeit von 15 Minuten wird die Behandlung abgebrochen.
          </Item>
          <Item title="Ich bin Beihilfeempfänger, was muss ich bezahlen?">
          Die Beihilfesätze sind bei der Beihilfe zu erfragen, die Differenz zum Beihilfesatz + PKV-Anteil zur Honorarvereinbarung muss selbst getragen werden!
          </Item>
          <Item title="Was muss ich als privat-versicherter Patient beachten?">Nach Erhalt der Honorarvereinbarung prüfen Sie vor der Behandlung, welchen Betrag die PKV übernimmt, und welcher Anteil von Ihnen getragen werden muss!</Item>
          <Item title="Wieviel Behandlungszeit steht mir als Patient zu?">Die Krankenkasse vergütet 15 Minuten Behandlungszeit – Ihnen stehen 15 Minuten Behandlung (Brutto!) zu!</Item>
          <Item title="Wie sind die Behandlungszeiten?">Je nach Verordnung, i.d.R. im 30 Minutentakt (Brutto)! Incl. Unterschreiben, An-/Ausziehen, Behandlung, Dokumentation!</Item>
          <Item title="Ist die Praxis behindertengerecht ausgestattet?">JA, die Praxis ist behindertengerecht ausgestattet!</Item>
          <Item title="Bis wann muss das Rezept, nach dem Arztbesuch, eingelöst werden?">Bis 28 Tage nach dem Ausstellungsdatum, muss das Rezept begonnen werden!</Item>
          <Item title="Kann die Behandlung unterbrochen werden?">JA, es gibt keine Unterbrechungsfristen, jedoch muss die Behandlung nach 3 Monaten abgeschlossen sein!</Item> */}
        </div>
      </div>
    </div>
  )
}

export default Faq
